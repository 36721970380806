export const mockPostLogin200 = {
  status_code: 200,
  status_message: '',
  PartnerType: 'CHARTER',
  accountNumber: '8353200020371581',
  customer: true,
  data: {
    Status: 'UNIQUE_AUTH',
    Identities: [
      {
        Username: 'worcester_gold@charter.net',
        PartnerType: 'CHARTER',
        OperationStatus: 'SUCCESS',
        IdentityUID: '74357854-0AE2-9572-ABF8-37006C1C7254',
        AccountNumber: '8353200020371581',
        SoloAccountId: '4501660',
        AccountUID: '14F14734-DC4E-3254-E6B4-2A7BC169FA96',
        Attrs: {
          IdentityType: 'EMAIL',
          CPNICompliance: 'NOT_COMPLIANT',
          ContactEmail: '',
          EmailVerified: false,
          FirstName: '',
          LastName: '',
          SourceSystemStatus: 'ACTIVE',
          SourceSystemRole: 'ADMIN',
          Role: 'ADMIN',
          IdentityRole: 'ADMIN',
          Status: 'ACTIVE',
        },
        AccountAttrs: {
          BillingAccountId: '8353200020371581',
          BillingDivisionId: 'CHTR',
          BillingSlice: '8353',
          SourceSystemStatus: 'ACTIVE',
          Status: 'ACTIVE',
          PostalCode: '37801-3702',
          AccountType: 'RESIDENTIAL',
        },
        Namespace: 'CHTR',
        UCAN: '10011309701',
        MFA: {
          enrollment: {
            status: 'NOT_PRESENTED',
          },
          'pref-option': {},
          'alt-options': [],
        },
        TacticAction: [],
        IdentityGuid: '74357854-0AE2-9572-ABF8-37006C1C7254',
        AccountGuid: '14F14734-DC4E-3254-E6B4-2A7BC169FA96',
      },
    ],
    AttemptNumber: 0,
    ClientIP: '10.42.1.0',
    SourcePort: '',
    TrafficOriginId: '123.22.123.0',
    AuthidResponseCode: '3100',
  },
  divisonId: 'CHTR',
  dsb_status: true,
  firstName: 'Calda',
  lastName: 'Test3',
  loginId: 'worcester_gold@charter.net',
  preferred_email: 'calda.jacobs@charter.com',
};
