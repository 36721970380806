/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';

import SpectrumVideo from '../common/SpectrumVideo';

// Hooks
import { useQuantum } from '../../hooks';

// Spectrum One Video
import Video from './Spectrum One Welcome Video_2024_20MB.mp4';
import Captions from './Spectrum One Welcome Video_2024.mp4.vtt';

// Styles
import './SpectrumOne.scss';

const SpectrumOne = () => {
  const { trackPageView } = useQuantum();

  // ==================================================
  // STATE
  // ==================================================

  // ==================================================
  // REACT QUERY
  // ==================================================

  // ==================================================
  // HELPERS
  // ==================================================

  // ==================================================
  // EFFECTS
  // ==================================================
  useEffect(() => trackPageView('Spectrum One'), [trackPageView]);

  // ==================================================
  // INTERACTION HANDLERS
  // ==================================================

  // ==================================================
  // Display Methods
  // ==================================================
  return (
    <div className="spectrumone">
      <SpectrumVideo video={Video} captions={Captions} />
    </div>
  );
};

export default SpectrumOne;
