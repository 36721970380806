/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';

// Common
import SpectrumVideo from '../../common/SpectrumVideo';
import SpeedBoostVideoPages from '../SpeedBoostVideosPages';

// Speed Boost Video
import Video from './Speed Boost Video 1.mp4';
import SpeedBoostVideoCaptions from './SpeedBoostVideoCaptions.vtt';

const SpeedBoost = () => {
  // ==================================================
  // STATE
  // ==================================================

  // ==================================================
  // REACT QUERY
  // ==================================================

  // ==================================================
  // HELPERS
  // ==================================================

  // ==================================================
  // EFFECTS
  // ==================================================

  // ==================================================
  // INTERACTION HANDLERS
  // ==================================================

  // ==================================================
  // Display Methods
  // ==================================================

  return (
    <SpeedBoostVideoPages displayName='Speed Boost'>
      <SpectrumVideo video={Video} captions={SpeedBoostVideoCaptions} />
    </SpeedBoostVideoPages>
  );
};

export default SpeedBoost;
