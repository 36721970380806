import React, { useState, useEffect, createContext, ReactNode } from 'react';

// Analytics
import AnalyticsService from '../utils/analytics/analytics';
import analyticsConfig from '../utils/analytics/analyticsConfig';
import packageJson from '../../package.json';

export const QuantumContext = createContext<AnalyticsService | null>(null);

interface IQuantumContextProviderProps {
  children: ReactNode;
}

export const QuantumContextProvider = ({
  children,
}: IQuantumContextProviderProps) => {
  const [analytics, setAnalytics] = useState<AnalyticsService | null>(null);

  useEffect(() => {
    const newAnalytics = new AnalyticsService(
      analyticsConfig,
      packageJson.version
    );

    setAnalytics(newAnalytics);
  }, []);

  return (
    <QuantumContext.Provider value={analytics}>
      {children}
    </QuantumContext.Provider>
  );
};
