import React, { createContext, useEffect, useMemo, useState } from 'react';

export interface IUserInfo {
  accountNumber: string;
  firstName: string;
  email: string;
}

export interface IUser {
  userInfo: IUserInfo | null;
  setUserInfo: (info: IUserInfo) => void;
}

export const UserContext = createContext<IUser>({
  userInfo: null,
  setUserInfo: () => {},
});

export interface IUserContextProviderProps {
  children: React.ReactNode;
}

export const UserContextProvider = ({
  children,
}: IUserContextProviderProps) => {
  const [userInfo, setUserInfo] = useState<IUserInfo | null>(null);

  const value = useMemo(
    () => ({
      setUserInfo,
      userInfo,
    }),
    [userInfo]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
