// Packages
import React from 'react';
import classNames from 'classnames';

// Components

// Hooks

// Utils

// Types

// Styles
import SpectrumLogo from '../../images/spectrum-logo.svg';
import './Header.scss';

export interface IHeaderProps {
  /** Optional css selector to pass into the component className */
  className?: string;
}

const Header = ({ className = '' }: IHeaderProps) => {
  return (
    <header className={`header ${className}`}>
      <img
        src={SpectrumLogo}
        alt="spectrum logo"
        className="header__spectrum-logo"
      />
    </header>
  );
};

export default Header;
