import packageJson from '../../../package.json';
import { appConfig } from '../../config/appConfig';

// https://prism.spectrumtoolbox.com/documentation/quantum/Quantum/home
export default {
  domain: appConfig.appType,
  customer: 'Charter',
  appName: 'SpectrumOffer',
  appType: 'Web',
  appVersion: packageJson.version,
  deviceType: 'webBrowser',
  requirementsVersion: '2.98', // ensure requirementsVersion up-to-date by checking https://prism.spectrumtoolbox.com/SolutionMappingToolAdmin@Web/quantum-resources/helix
  msgTriggeredBy: 'user',
  startSession: 'SpectrumOffer_startSession_generic',
  settings: {
    logLevel: 'error',
  },
};
