// Packages
import React from 'react';

// Components

// Hooks

// Utils

// Styles
import './Footer.scss';

// Constants
const FULL_YEAR: number = new Date().getFullYear();

export interface IFooterProps {
  /** Optional css selector to pass into the component className */
  className?: string;
}

/** Update this description, which will appear in the Storybook documentation */

const Footer = ({ className = '' }: IFooterProps) => {
  return (
    <footer className="footer">
      <ul className="footer__links">
        <li className="footer__link footer__link--border">
          <a href="https://www.spectrum.com/policies/your-privacy-rights">
            Your Privacy Rights
          </a>
        </li>
        <li className="footer__link">
          <a href="https://www.spectrum.com/policies/terms-of-service">
            Policies
          </a>
        </li>
      </ul>
      <p className="footer__copyright">©{FULL_YEAR} Charter Communications</p>
    </footer>
  );
};

export default Footer;
