/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useEffect } from 'react';

// Styles
import { KiteButton } from '@kite/react-kite';
import './SpeedBoostVideosPages.scss';
import { useQuantum } from '../../hooks';

export interface ISpeedBoostVideosPages {
    displayName: string;
    children?: any
}

const SpeedBoostVideosPages = ({ displayName, children = null }: ISpeedBoostVideosPages) => {
  // ==================================================
  // STATE
  // ==================================================
  const { trackPageView, trackSelectAction } = useQuantum();

  // ==================================================
  // REACT QUERY
  // ==================================================

  // ==================================================
  // HELPERS
  // ==================================================

  // ==================================================
  // EFFECTS
  // ==================================================
  useEffect(() => trackPageView(displayName), [trackPageView]);

  // ==================================================
  // INTERACTION HANDLERS
  // ==================================================

  // ==================================================
  // Display Methods
  // ==================================================
  
  const handleButtonClick = useCallback(() => {
    const url = "https://www.spectrum.net/support/mobile/spectrum-mobile-speed-boost";
    window.open(url, '_blank', 'noopener, noreferrer');
    trackSelectAction(displayName, { currPageElemStdName: "Learn more", msgCategory: 'navigation', msgTriggeredBy: 'user', opType: 'buttonClick' });
  }, []);

  return (
    <div className="speedboostvideospages">
      <h1>Meet Speed Boost</h1>
      <h2>Go FASTER at no additional cost</h2>
      <div className="speedboostvideospages">
        {children}
      </div>
      <KiteButton size='md' onClick={handleButtonClick} >Learn more</KiteButton>
      <p>Speed Boost is available on Spectrum Mobile Devices when connected to Spectrum Advanced Wifi.</p>
    </div>
  );
};

export default SpeedBoostVideosPages;
