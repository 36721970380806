// Packages
import React from 'react';
import ReactDOM from 'react-dom';

// Components
import App from './App';
import Providers from './Providers';

// Utils
import worker from './mocks/worker';

// Styles
import './index.css';

const root = document.getElementById('root');

if (!root) {
  throw new Error('Failed to find the root element');
}

const startMSW = async () => {
  await worker.start();
};

if (process.env.NODE_ENV === 'development') {
  startMSW().catch(() => console.log('msw error'));
}

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  root
);
