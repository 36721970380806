// Packages
import React from 'react';
import { ClearCacheProvider } from 'react-clear-cache';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// Contexts
import {
  KeycloakUserContextProvider,
  QuantumContextProvider,
  UserContextProvider,
} from './contexts';

interface IProvidersProps {
  children?: any;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Providers = ({ children = null }: IProvidersProps) => {
  return (
    <ClearCacheProvider duration={5000}>
      <QuantumContextProvider>
        <KeycloakUserContextProvider>
          <QueryClientProvider client={queryClient}>
            <UserContextProvider>{children}</UserContextProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </KeycloakUserContextProvider>
      </QuantumContextProvider>
    </ClearCacheProvider>
  );
};

export default Providers;
