/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';

// Components
import { KiteCard, KiteLink } from '@kite/react-kite';

// Hooks
import { useQuantum } from '../../hooks';

// Styles
import './NotFound.scss';

// Image
import Image from './NotFound.png';

const NotFound = () => {
  const { trackPageView } = useQuantum();

  // ==================================================
  // STATE
  // ==================================================

  // ==================================================
  // REACT QUERY
  // ==================================================

  // ==================================================
  // HELPERS
  // ==================================================

  // ==================================================
  // EFFECTS
  // ==================================================
  useEffect(() => trackPageView('Route Not Found'), [trackPageView]);

  // ==================================================
  // INTERACTION HANDLERS
  // ==================================================

  // ==================================================
  // Display Methods
  // ==================================================
  return (
    <div className="notfound">
      <KiteCard className="notfound__card kite-typography">
        <h1 className="notfound__title">Page Not Found</h1>
        <p>
          We're sorry, we couldn't find this page. We may have moved it, or you
          might have a typo in your web address.
        </p>
        <p>
          You can search for the page or find help in our <KiteLink href="https://www.spectrum.net/support" target="_blank" rel="noopener noreferrer">Support</KiteLink> section
        </p>
        <img src={Image} alt="Not Found" />
      </KiteCard>
    </div>
  );
};

export default NotFound;
