/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';

// Common
import SpectrumVideo from "../common/SpectrumVideo";

// Hooks
import { useQuantum } from '../../hooks';

// Flagship Video
import Video from './Pre-CRO Upsell.mp4';
import Captions from './Pre-CRO Upsell.b0172e05c717fefd2b04.mp4.vtt';

// Styles
import './Flagship.scss';

const Flagship = () => {
  const { trackPageView } = useQuantum();

  // ==================================================
  // STATE
  // ==================================================

  // ==================================================
  // REACT QUERY
  // ==================================================

  // ==================================================
  // HELPERS
  // ==================================================

  // ==================================================
  // EFFECTS
  // ==================================================
  useEffect(() => trackPageView('Flagship'), [trackPageView]);

  // ==================================================
  // INTERACTION HANDLERS
  // ==================================================

  // ==================================================
  // Display Methods
  // ==================================================
  return (
    <div className='flagship'>
      <SpectrumVideo video={Video} captions={Captions} />
    </div>
  );
};

export default Flagship;
