import React, { createContext, useState, useMemo, SetStateAction } from 'react';

export interface IKeycloakUserContextProviderProps {
  children: React.ReactNode;
}

interface IObject {
  [key: string]: any;
}

interface IKeycloakUserContext {
  keycloakUser: any;
  setKeycloakUser: SetStateAction<any> | null;
}

export const KeycloakUserContext = createContext<IKeycloakUserContext>({
  keycloakUser: null,
  setKeycloakUser: null,
});

export const KeycloakUserContextProvider = ({
  children,
}: IKeycloakUserContextProviderProps) => {
  const [keycloakUser, setKeycloakUser] = useState<IObject | null>(null);

  const value = useMemo(
    () => ({ keycloakUser, setKeycloakUser }),
    [keycloakUser]
  );

  return (
    <KeycloakUserContext.Provider value={value}>
      {children}
    </KeycloakUserContext.Provider>
  );
};
