/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';

// Common
import SpectrumVideo from '../../common/SpectrumVideo';
import SpeedBoostVideoPages from '../SpeedBoostVideosPages';

// Equipment Swap Video
import Video from './Speed Boost Video 3 - Equipment Swap.mp4';
import EquipmentSwapVideoCaptions from './EquipmentSwapVideoCaptions.vtt';

const EquipmentSwap = () => {
  // ==================================================
  // STATE
  // ==================================================

  // ==================================================
  // REACT QUERY
  // ==================================================

  // ==================================================
  // HELPERS
  // ==================================================

  // ==================================================
  // EFFECTS
  // ==================================================

  // ==================================================
  // INTERACTION HANDLERS
  // ==================================================

  // ==================================================
  // Display Methods
  // ==================================================
  
  return (
    <SpeedBoostVideoPages displayName='Equipment Swap'>
      <SpectrumVideo video={Video} captions={EquipmentSwapVideoCaptions} />
    </SpeedBoostVideoPages>
  );
};

export default EquipmentSwap;
