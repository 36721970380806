import React from 'react';
import { useRoutes } from 'react-router-dom';

import { Layout } from '../components';
import { Business, EquipmentSwap, Flagship, NotFound, SpectrumOne, SpeedBoost, TakeAction } from '../pages';

const Routes = () =>
  useRoutes([
    {
      element: <Layout pathName={window.location.pathname} />,
      path: '/',
      children: [
        { path: 'business', element: <Business /> },
        { path: 'equipmentswap', element: <EquipmentSwap /> },
        { path: 'flagship', element: <Flagship /> },
        { path: 'speedboost', element: <SpeedBoost /> },
        { path: 'spectrumone', element: <SpectrumOne /> },
        { path: 'takeaction', element: <TakeAction /> },
        { path: '*', element: <NotFound /> },
      ],
    },
  ]);

export default Routes;
