import * as React from 'react';
import classNames from 'classnames';

import './KiteTable.scss';

export interface ITableColumn {
  label: string;
  key: string;
  render?: (item: object) => any;
  width?: string;
}

export interface IKiteTableProps {
  /** The columns prop is an array of objects each with a key property and optional label property. If no label prop is passed the key will be rendered as the label. The key tells the table which key/value pair to look for in your tableData for that column.  */
  columns?: ITableColumn[];
  /** The tableData prop should be an array of objects. */
  tableData?: { [x: string]: any }[];
  /** If true, shows a grey background on even-numbered rows. Default is `false` */
  useStripes?: boolean;
  /** Any additional classNames for the component */
  className?: string;
}

/**
 * A standard data table with column headers, utilizing the HTML `<table>` element and appropriate children. [Table design guidelines](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/data-tables)
 *
 */
const KiteTable = ({
  useStripes,
  columns,
  tableData,
  className,
}: IKiteTableProps) => {
  const displayHeaders = columns
    ? columns.map(({ key, label, width }) => (
        <th key={key || label} style={width ? { width } : {}}>
          {label || key}
        </th>
      ))
    : [];

  const displayRows = tableData
    ? tableData.map((item, index) => (
        // eslint-disable-next-line no-underscore-dangle
        <tr key={item.uuid || item.id || index}>
          {columns
            ? columns.map(({ key, render, width }) => (
                // eslint-disable-next-line no-underscore-dangle
                <td
                  key={`${item[key]}-${item.uuid || item.id || index}`}
                  style={width ? { width } : {}}
                >
                  {render ? render(item) : item[key]}
                </td>
              ))
            : []}
        </tr>
      ))
    : [];

  return (
    <div className="kite-table__wrapper">
      <table
        className={classNames({
          'kite-table': true,
          'kite-table-striped': useStripes,
          [className || '']: className,
        })}
        style={{ textAlign: 'left' }}
      >
        <thead>
          <tr>{displayHeaders}</tr>
        </thead>
        <tbody>{displayRows}</tbody>
      </table>
    </div>
  );
};

KiteTable.defaultProps = {
  useStripes: false,
  columns: [],
  tableData: [],
  className: '',
};

export default KiteTable;
