import axios from 'axios';
import { ILoginFormState } from '../pages/Login/Login';
import { getUrlPrefix } from './getUrlPrefix';
import { throwError } from './throwError';

interface IPostLoginArgs {
  formData: ILoginFormState;
  ipAddress: string;
}

export const postLogin = async ({ formData, ipAddress }: IPostLoginArgs) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `${getUrlPrefix()}/login`,
      headers: {
        Accept: '*/*',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'X-Real-IP': ipAddress,
      },
      data: JSON.stringify({
        Username: formData.username,
        Password: formData.password,
      }),
    });
    return res.data;
  } catch (error: any) {
    throw new Error(error, { cause: error?.response?.status });
  }
};
