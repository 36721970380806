/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

export interface ISpectrumVideo {
    video: any;
    captions?: any;
    showCaptionsByDefault?: boolean;
}

const SpectrumVideo = ({ video, captions = null, showCaptionsByDefault = false }: ISpectrumVideo) => {
  // ==================================================
  // STATE
  // ==================================================

  // ==================================================
  // REACT QUERY
  // ==================================================

  // ==================================================
  // HELPERS
  // ==================================================

  // ==================================================
  // EFFECTS
  // ==================================================

  // ==================================================
  // INTERACTION HANDLERS
  // ==================================================

  // ==================================================
  // Display Methods
  // ==================================================
  return (
    <video controls>
        <source src={video} type='video/mp4' />
        <track default={showCaptionsByDefault} src={captions} kind='captions' label='English' />
    </video>
  );
};

export default SpectrumVideo;
