import { rest } from 'msw';
import { getUrlPrefix } from '../apiCalls/getUrlPrefix';
import { mockPostLogin200 } from './mockPostLogin200';

export default [
  rest.post(`${getUrlPrefix()}/login`, (req, res, ctx) => {
    // return res(ctx.status(401));
    // return res(ctx.status(500));
    return res(ctx.status(200), ctx.delay(500), ctx.json(mockPostLogin200));
  }),
];
