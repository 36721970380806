/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';

// Common
import SpectrumVideo from "../common/SpectrumVideo";

// Hooks
import { useQuantum } from '../../hooks';

// SMB Pro Install Video
import Video from './SMB_Pro Install.mp4';
import BusinessVideoCaptions from './SMB_Pro Install.mp4_edited.vtt';

// Styles
import './Business.scss';

const Business = () => {
  const { trackPageView } = useQuantum();

  // ==================================================
  // STATE
  // ==================================================

  // ==================================================
  // REACT QUERY
  // ==================================================

  // ==================================================
  // HELPERS
  // ==================================================

  // ==================================================
  // EFFECTS
  // ==================================================
  useEffect(() => trackPageView('SMB Pro Install'), [trackPageView]);

  // ==================================================
  // INTERACTION HANDLERS
  // ==================================================

  // ==================================================
  // Display Methods
  // ==================================================
  return (
    <div className='business'>
      <SpectrumVideo video={Video} captions={BusinessVideoCaptions} />
    </div>
  );
};

export default Business;
