// Packages
import { KiteAlert } from '@kite/react-kite';
import React from 'react';

// Components

// Hooks

// Utils

// Types

// Styles
import './ErrorAlert.scss';

export interface IErrorAlertProps {
  /** Optional css selector to pass into the component className */
  className?: string;
}

/** Update this description, which will appear in the Storybook documentation */

const ErrorAlert = ({ className = '' }: IErrorAlertProps) => {
  return (
    <KiteAlert
      level="global"
      type="error"
      description="We're sorry, something didn't work quite right. Please try again later. (Reference Code: MSGE-001)"
    />
  );
};

export default ErrorAlert;
